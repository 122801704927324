import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            
            <li><Link to="/about">About</Link></li>

            <li className="has-droupdown"><Link to="#">Products</Link>
                <ul className="submenu">
                    <li><a href="https://connect.alpaon.com/" target="_blank">AlpaConnect</a></li>
                    <li><a href="https://synap.us/" target="_blank">Synapus</a></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
